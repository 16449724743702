const FLAGS = {
  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-3157
  SE3096_IT_CLOSET: 'se-3096-it-closet',

  // Remove flag -
  SE3106_DELIVERY_STATUS: 'se-3106-add-delivery-statistics',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2802
  SE2220_SUPPORTED_COUNTRIES: 'SE-2220-supported-countries',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2713
  SE555_US_VERIFICATION: 'SE-555-united-states-verification',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2247
  SE2170_ADMIN_DASHBOARD: 'se-2170-admin-dashboard',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2246
  SE2154_PRODUCT_PORTAL_LINK: 'se-2154-show-product-portal-link-app-cues',

  // flag removed when one off orders is complete
  SE1317_ONE_OFF_ORDERS: 'se-1317-one-off-orders',

  // flag removed when developer controls available for all users (liaise with RVB)
  PUBLIC_API_BETA_USER: 'public-api-beta-user',

  // remove once help center has been launched (https://firstbasehq.atlassian.net/browse/SE-1560)
  SE1525_HELP_CENTER_LINK: 'se-1525-help-center-link',

  // remove flag once validation testing has occurred in all environments
  SE1241_APPCUES: 'se-1241-appcues',

  // remove flag once returns are tested and available to everyone (https://firstbasehq.atlassian.net/browse/SE-2018)
  SE2018_EXPEDITED_SHIPPING: 'se-2018-expedited-shipping',

  // remove flag once validation and testing has occurred in all environments
  SE2322_RESEND_ACTIVATION_EMAIL: 'se-2322-resend-activation-email',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2522)
  SE2522_INTEGRATIONS_PAGE: 'se-2522-integrations-page',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2339)
  SE_2339_API_TOKEN_PAGE_UPDATES: 'se-2339-api-token-page-updates',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2685)
  SE2685_INTERNATIONAL_ADDRESS_VALIDATION:
    'se-2685-international-address-validation',

  // remove flag once holiday loader no longer needed (https://firstbasehq.atlassian.net/browse/SE-3024)
  SE_3019_HOLIDAY_LOADER: 'SE-3019-holiday-loader',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3170)
  SE_3170_ADD_CONDITION_INTO_CUSTOMER_SHIPMENT_NOTICES:
    'se-3170-add-condition-into-customer-shipment-notices',

  // remove flag once feature is available to all users
  SE_3180_BUYOUTS: 'se-3180-buyouts-fe',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_TAB_ADMIN_APP: 'se-3551-automations-tab-in-admin-app',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_EMAIL_SWAPPING_ONBOARDING_SETTING:
    'se-3551-automations-email-swapping-onboarding-setting',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_EMAIL_SWAPPING_OFFBOARDING_SETTING:
    'se-3551-automations-email-swapping-offboarding-setting',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4311)
  SE_4311_DROP_OFF_AT_OFFICE: 'se-4311-drop-off-at-office',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4289)
  SE_4289_RETURN_TO_OFFICE_OFFBOARDING: 'se-4289-return-to-office-offboarding',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4225)
  SE_4073_IT_CLOSET_ENABLED_FLAG_TOGGLING_SUPPORT:
    'se-4073-it-closet-enabled-flag-toggling-support',

  // Remove flag when okta recipe is updated with employment types for all clients in Workato (https://firstbasehq.atlassian.net/browse/SE-4670)
  SE_4670_INTEGRATIONS_OKTA_ONBOARDING_EMPLOYMENT_TYPES:
    'se-4670-integrations-okta-onboarding-employment-types',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-3971)
  SE_3971_CREATE_PACKAGELESS_PEOPLE: 'SE-3971-create-packageless-people',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4562)
  SE_4562_USAGE_REPORT_AND_FEES_MVP: 'se-4562-usage-report-and-fees-mvp',

  // Don't remove until all clients can ship to UK warehouses (https://firstbasehq.atlassian.net/browse/SE-6197)
  SE_6197_SHOW_UK_WAREHOUSE_OPTIONS_FOR_SHIPMENT_NOTICES:
    'se-6197-show-uk-warehouse-options-for-shipment-notices',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-5970)
  SE_5970_GEO_ORDER_GUARDRAILS: 'se-5970-geo-order-guardrails',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-6458)
  SE_6458_ASSET_MANAGEMENT_EXPORT_MODAL:
    'se-6458-asset-management-export-modal',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/admin-self-service-standard-catalog-7b868ae9b038/PE)
  SE_4591_STANDARD_CATALOG_M2: 'se-4591-standard-catalog-m-2',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/admin-self-service-standard-catalog-7b868ae9b038/PE)
  PE_343_CUSTOMER_CATALOG_REMOVE_ITEMS: 'PE-343-customer-catalog-remove-items',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/shipment-notice-enhancements-0d30226c3fb9)
  PE_329_SHIPMENT_NOTICE_ENHANCEMENTS_M_1:
    'pe-329-shipment-notice-enhancements-m-1',

  // Remove flag when used by all
  PE_792_SKU_METADATA_M_2: 'pe-792-sku-metadata-m-2',

  // Remove flag when used by all
  PE_846_ARCHIVE_ASSETS: 'pe-846-archive-assets',

  // Remove flag when used by all
  PE_501_EXPORT_PACKAGES_AS_CSV: 'pe-501-export-packages-as-csv',

  // No removal until further notice
  PE_965_FIRSTBASE_ASSISTANT: 'pe-965-firstbase-assistant',

  // Remove flag when used by all
  PE_116_LEAVER_RETURN_KIT_TRACKER: 'pe-116-leaver-return-kit-tracker',

  PE_805_ORDERS_UPDATED_AT: 'pe-805-orders-updated-at',

  PE_982_MERGE_INTEGRATIONS: 'pe-982-merge-integrations',

  // Remove after 6 weeks have passed since it was turned on
  PE_1113_ONE_OFF_RETURNS_OFFBOARDING_QUESTION:
    'pe-1113-one-off-returns-offboarding-question',

  // Remove after 6 weeks have passed since it was turned on
  PE_1097_SHIPMENT_NOTICES_MILESTONE_4: 'pe-1097-shipment-notices-milestone-4',

  // Remove after 6 weeks have passed since it was turned on
  PE_1141_SHIPMENT_NOTICES_MILESTONE_5: 'pe-1141-shipment-notices-milestone-5',

  // Remove flag when integrations are fully migrated
  PE_1409_HIDE_OLD_INTEGRATIONS: 'pe-1409-hide-old-integrations',

  // Remove flag when feature is available to all users
  PE_1363_SHOW_WEBHOOK_INTEGRATION: 'pe-1363-show-webhook-integration',

  // Remove flag when feature is available to all users
  PE_1671_MDM_SUPPORT_MVP: 'pe-1671-mdm-support-mvp',

  // Remove flag when feature is available to all users
  PE_1561_ASSET_MANAGEMENT_BILLING_INFO:
    'pe-1561-asset-management-billing-info',

  // Remove flag when available to all users for over 6 weeks
  PE_1603_NOTICES_TRACKING_CODE_VALIDATION:
    'pe-1603-notices-tracking-code-validation',

  // Remove flag when team says it's ready.
  PE_1773_PRODUCT_TIERS: 'PE-1773-product-tiers-2',

  // Remove flag once it's been available to all users for over 6 weeks
  LOGISTICS_PAGE_COUNTRY_FILTERS: 'logistics-page-country-filters',

  // Remove flag when feature is available to all users for over 6 weeks
  WARRANTIES_APP: 'warranties-app',

  // Remove flag if it's been available to all users for over 6 weeks
  ADMIN_CATALOG_REDESIGN_APP: 'admin-catalog-redesign-app',

  // Remove flag when feature is available to all users for over 6 weeks
  IT_ASSET_DISPOSITION_M_1: 'it-asset-disposition-m-1',

  // Remove flag when feature is available to all users for over 6 weeks
  PE_2600_ACCOUNTING_INTEGRATIONS: 'pe-2600-accounting-integrations',
} as const

export default FLAGS
