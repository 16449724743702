import { Button, Icon } from '@firstbase/components'
import { cn } from '@firstbase/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { AlertIcon } from './AlertIcon'

const alertVariants = cva('relative w-full rounded-lg border text-gray-600', {
  variants: {
    variant: {
      info: 'border-blue-300 bg-blue-50',
      error: 'border-red-300 bg-red-50',
      success: 'border-green-300 bg-green-50',
      warning: 'border-orange-300 bg-orange-50',
      neutral: 'border-gray-300 bg-gray-50',
    },
    size: {
      sm: 'p-1 px-2',
      md: 'p-2',
    },
  },
  defaultVariants: {
    variant: 'neutral',
    size: 'md',
  },
})

const alertContentVariants = cva('flex flex-1 flex-col justify-start gap-1', {
  variants: {
    size: {
      sm: 'text-body-xs',
      md: 'text-body-sm',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const closeButtonVariants = cva(undefined, {
  variants: {
    size: {
      sm: 'size-4',
      md: 'size-5',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertVariants> & {
      onClose?: () => void
    }
>(({ className, variant, size, onClose, children, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(
      alertVariants({ variant, size }),
      'flex items-start gap-x-3',
      className
    )}
    {...props}
  >
    <AlertIcon variant={variant} size={size} />

    <div className={cn(alertContentVariants({ size }))}>{children}</div>

    {onClose && (
      <Button
        variant="ghost"
        onClick={onClose}
        size="icon-xs"
        className={cn(
          'shrink-0 text-gray-900 hover:bg-transparent hover:text-gray-600'
        )}
      >
        <Icon name="cross" className={closeButtonVariants({ size })} />
      </Button>
    )}
  </div>
))
Alert.displayName = 'Alert'

export { Alert }
