export const dynamicIconImports = {
  accordionMinus: () =>
    import('./IconComponents/AccordionMinusIcon').then((module) => ({
      default: module.AccordionMinusIcon,
    })),
  accordionPlus: () =>
    import('./IconComponents/AccordionPlusIcon').then((module) => ({
      default: module.AccordionPlusIcon,
    })),
  'alert-triangle': () =>
    import('./IconComponents/AlertTriangleIcon').then((module) => ({
      default: module.AlertTriangleIcon,
    })),
  alertBell: () =>
    import('./IconComponents/AlertBellIcon').then((module) => ({
      default: module.AlertBellIcon,
    })),
  alertBellActive: () =>
    import('./IconComponents/AlertBellActiveIcon').then((module) => ({
      default: module.AlertBellActiveIcon,
    })),
  alertBellSlash: () =>
    import('./IconComponents/AlertBellSlashIcon').then((module) => ({
      default: module.AlertBellSlashIcon,
    })),
  arrow: () =>
    import('./IconComponents/ArrowIcon').then((module) => ({
      default: module.ArrowIcon,
    })),
  back: () =>
    import('./IconComponents/BackIcon').then((module) => ({
      default: module.BackIcon,
    })),
  book: () =>
    import('./IconComponents/BookIcon').then((module) => ({
      default: module.BookIcon,
    })),
  'box-default': () =>
    import('./IconComponents/BoxDefaultIcon').then((module) => ({
      default: module.BoxDefaultIcon,
    })),
  box: () =>
    import('./IconComponents/BoxIcon').then((module) => ({
      default: module.BoxIcon,
    })),
  boxTwo: () =>
    import('./IconComponents/BoxTwoIcon').then((module) => ({
      default: module.BoxTwoIcon,
    })),
  calendarDates: () =>
    import('./IconComponents/CalendarDatesIcon').then((module) => ({
      default: module.CalendarDatesIcon,
    })),
  cart: () =>
    import('./IconComponents/CartIcon').then((module) => ({
      default: module.CartIcon,
    })),
  catalog: () =>
    import('./IconComponents/CatalogIcon').then((module) => ({
      default: module.CatalogIcon,
    })),
  'check-circle': () =>
    import('./IconComponents/CheckCircleIcon').then((module) => ({
      default: module.CheckCircleIcon,
    })),
  check: () =>
    import('./IconComponents/CheckIcon').then((module) => ({
      default: module.CheckIcon,
    })),
  'chevron-down': () =>
    import('./IconComponents/ChevronDownIcon').then((module) => ({
      default: module.ChevronDownIcon,
    })),
  'chevron-up': () =>
    import('./IconComponents/ChevronUpIcon').then((module) => ({
      default: module.ChevronUpIcon,
    })),
  chevronDownWhite: () =>
    import('./IconComponents/ChevronDownWhiteIcon').then((module) => ({
      default: module.ChevronDownWhiteIcon,
    })),
  close: () =>
    import('./IconComponents/CloseIcon').then((module) => ({
      default: module.CloseIcon,
    })),
  compass: () =>
    import('./IconComponents/CompassIcon').then((module) => ({
      default: module.CompassIcon,
    })),
  'cross-circle': () =>
    import('./IconComponents/CrossCircleIcon').then((module) => ({
      default: module.CrossCircleIcon,
    })),
  cross: () =>
    import('./IconComponents/CrossIcon').then((module) => ({
      default: module.CrossIcon,
    })),
  down: () =>
    import('./IconComponents/DownIcon').then((module) => ({
      default: module.DownIcon,
    })),
  edit: () =>
    import('./IconComponents/EditIcon').then((module) => ({
      default: module.EditIcon,
    })),
  email: () =>
    import('./IconComponents/EmailIcon').then((module) => ({
      default: module.EmailIcon,
    })),
  equipment: () =>
    import('./IconComponents/EquipmentIcon').then((module) => ({
      default: module.EquipmentIcon,
    })),
  error: () =>
    import('./IconComponents/ErrorIcon').then((module) => ({
      default: module.ErrorIcon,
    })),
  'file-check': () =>
    import('./IconComponents/FileCheckIcon').then((module) => ({
      default: module.FileCheckIcon,
    })),
  gear: () =>
    import('./IconComponents/GearIcon').then((module) => ({
      default: module.GearIcon,
    })),
  help: () =>
    import('./IconComponents/HelpIcon').then((module) => ({
      default: module.HelpIcon,
    })),
  home: () =>
    import('./IconComponents/HomeIcon').then((module) => ({
      default: module.HomeIcon,
    })),
  'illustration-box-replace': () =>
    import('./IconComponents/IllustrationBoxReplaceIcon').then((module) => ({
      default: module.IllustrationBoxReplaceIcon,
    })),
  'illustration-box-return': () =>
    import('./IconComponents/IllustrationBoxReturnIcon').then((module) => ({
      default: module.IllustrationBoxReturnIcon,
    })),
  'illustration-document-question': () =>
    import('./IconComponents/IllustrationDocumentQuestionIcon').then(
      (module) => ({
        default: module.IllustrationDocumentQuestionIcon,
      })
    ),
  'illustration-location': () =>
    import('./IconComponents/IllustrationLocationIcon').then((module) => ({
      default: module.IllustrationLocationIcon,
    })),
  'illustration-truck-right': () =>
    import('./IconComponents/IllustrationTruckRightIcon').then((module) => ({
      default: module.IllustrationTruckRightIcon,
    })),
  info: () =>
    import('./IconComponents/InfoIcon').then((module) => ({
      default: module.InfoIcon,
    })),
  logistics: () =>
    import('./IconComponents/LogisticsIcon').then((module) => ({
      default: module.LogisticsIcon,
    })),
  mappin: () =>
    import('./IconComponents/MappinIcon').then((module) => ({
      default: module.MappinIcon,
    })),
  menu: () =>
    import('./IconComponents/MenuIcon').then((module) => ({
      default: module.MenuIcon,
    })),
  minus: () =>
    import('./IconComponents/MinusIcon').then((module) => ({
      default: module.MinusIcon,
    })),
  modules: () =>
    import('./IconComponents/ModulesIcon').then((module) => ({
      default: module.ModulesIcon,
    })),
  notifications: () =>
    import('./IconComponents/NotificationsIcon').then((module) => ({
      default: module.NotificationsIcon,
    })),
  pen: () =>
    import('./IconComponents/PenIcon').then((module) => ({
      default: module.PenIcon,
    })),
  people: () =>
    import('./IconComponents/PeopleIcon').then((module) => ({
      default: module.PeopleIcon,
    })),
  person: () =>
    import('./IconComponents/PersonIcon').then((module) => ({
      default: module.PersonIcon,
    })),
  pin: () =>
    import('./IconComponents/PinIcon').then((module) => ({
      default: module.PinIcon,
    })),
  plus: () =>
    import('./IconComponents/PlusIcon').then((module) => ({
      default: module.PlusIcon,
    })),
  profile: () =>
    import('./IconComponents/ProfileIcon').then((module) => ({
      default: module.ProfileIcon,
    })),
  receipt: () =>
    import('./IconComponents/ReceiptIcon').then((module) => ({
      default: module.ReceiptIcon,
    })),
  remove: () =>
    import('./IconComponents/RemoveIcon').then((module) => ({
      default: module.RemoveIcon,
    })),
  rocket: () =>
    import('./IconComponents/RocketIcon').then((module) => ({
      default: module.RocketIcon,
    })),
  rocketActive: () =>
    import('./IconComponents/RocketActiveIcon').then((module) => ({
      default: module.RocketActiveIcon,
    })),
  search: () =>
    import('./IconComponents/SearchIcon').then((module) => ({
      default: module.SearchIcon,
    })),
  signout: () =>
    import('./IconComponents/SignoutIcon').then((module) => ({
      default: module.SignoutIcon,
    })),
  success: () =>
    import('./IconComponents/SuccessIcon').then((module) => ({
      default: module.SuccessIcon,
    })),
  tertiaryButtonSelectDown: () =>
    import('./IconComponents/TertiaryButtonSelectDownIcon').then((module) => ({
      default: module.TertiaryButtonSelectDownIcon,
    })),
  truck: () =>
    import('./IconComponents/TruckIcon').then((module) => ({
      default: module.TruckIcon,
    })),
  upArrow: () =>
    import('./IconComponents/UpArrowIcon').then((module) => ({
      default: module.UpArrowIcon,
    })),
  warning: () =>
    import('./IconComponents/WarningIcon').then((module) => ({
      default: module.WarningIcon,
    })),
}
