import React from 'react'

import { Typography } from '@firstbase/components'

interface HeadingProps {
  kind?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  title: React.ReactNode
  className?: string
  bold?: boolean
}

const Heading = ({
  kind = 'h1',
  title,
  className,
  bold,
  ...props
}: HeadingProps) => (
  <Typography variant={kind} isBold={bold} className={className} {...props}>
    {title}
  </Typography>
)

export default Heading
