import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import classNamesBind from 'classnames/bind'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'

import environmentVariables from '@utils/environmentVariables'

import Input from '@firstbase/components/Atoms/Input'
import Button from '@firstbase/components/Atoms/Button'
import ButtonRow from '@firstbase/components/Atoms/ButtonRow'
import Heading from '@firstbase/components/Atoms/Heading'
import InlineAlert from '@firstbase/components/Atoms/InlineAlert/InlineAlert'
import FirstbaseLogo from '@firstbase/assets/firstbaseLogo.svg'

import css from './activate.module.css'

interface ActivateFormFields {
  password: string
  passwordConfirm: string
}

const classNames = classNamesBind.bind(css)

const Activate = () => {
  const { search } = useLocation()
  const { oktaAuth } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const [error, setError] = useState<false | string>(false)
  const [password] = useState('')
  const [passwordConfirm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const validation = () =>
    yup.object().shape({
      password: yup
        .string()
        .trim()
        .required(t('forms.Password is required.'))
        .matches(
          /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
          t(
            'forms.Password must contain at least 8 characters, a lowercase letter, an uppercase letter, a number, and not include parts of your username'
          )
        ),
      passwordConfirm: yup
        .string()
        .nullable()
        .trim()
        .oneOf([yup.ref('password'), null], t('forms.Passwords must match'))
        .required(t('forms.Confirm password is required.')),
    })

  const initialValues = { password, passwordConfirm }

  const { register, handleSubmit, formState } = useForm({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: yupResolver(validation()),
  })

  const { errors: validationErrors } = formState

  useEffect(() => {
    const checkIfAuthed = async () => {
      const isAuthed = await oktaAuth.isAuthenticated()
      if (isAuthed) history.replace(`/`)
    }

    checkIfAuthed()
  }, [history, oktaAuth])

  const getActivationFailedMessage = (body: any) => {
    const errorFromBackend = body && body.error ? body.error : undefined
    // use to protect since body is any
    const errorFromBackendIsString = typeof errorFromBackend === 'string'

    if (errorFromBackendIsString) {
      return errorFromBackend.includes('password requirements')
        ? t(
            'forms.Password requirements were not met. Please make sure you are not using parts of your username in the password.'
          )
        : errorFromBackend
    }

    // default
    return 'token not valid'
  }

  const activate = async (pw: string) => {
    const params = new URLSearchParams(search)
    const id = params.get('id')

    try {
      setIsLoading(true)
      const response = await fetch(
        `${environmentVariables.get().VITE_API_URI}/activate`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            id: `${id}`,
            password: `${pw}`,
          }),
        }
      )
      const body = await response.json()

      if (response.ok) {
        if (body.sessionToken) {
          oktaAuth.signInWithRedirect({ sessionToken: body.sessionToken })
        } else {
          setIsLoading(false)
          setError(
            `${t(
              'Activation.This account activation link is no longer valid. Please contact support@firstbase.com'
            )}`
          )
        }
      } else {
        setIsLoading(false)
        setError(
          `${t('Activation.Activation failed - reason', {
            reason: getActivationFailedMessage(body),
          })}`
        )
      }
    } catch (err: any) {
      setIsLoading(false)
      setError(
        `${t('Activation.Activation failed - reason', {
          reason: err?.message,
        })}`
      )
    }
  }

  const onSubmit = (fields: ActivateFormFields) => {
    const { password: pw } = fields

    activate(pw)
  }

  const renderAlert = () => {
    if (!error) return null

    return <InlineAlert text={error} />
  }

  return (
    <form className={classNames('container')} onSubmit={handleSubmit(onSubmit)}>
      <FirstbaseLogo width={200} fill="#00000" />
      <Heading
        className={classNames('heading')}
        title={t('forms.Set your password')}
        kind="h2"
      />
      <Input
        label={t('forms.Password')}
        htmlFor="password"
        type="password"
        {...register('password', { required: true })}
        error={validationErrors?.password?.message}
        disabled={isLoading}
      />
      <Input
        label={t('forms.Confirm Password')}
        htmlFor="passwordConfirm"
        type="password"
        {...register('passwordConfirm', { required: true })}
        error={validationErrors?.passwordConfirm?.message}
        disabled={isLoading}
      />
      {renderAlert()}
      <ButtonRow>
        <Button
          flexFill
          noMargin
          label={t('forms.Confirm')}
          type="submit"
          loading={isLoading}
          disabled={isLoading || !!Object.keys(validationErrors).length}
        />
      </ButtonRow>
    </form>
  )
}

export default Activate
