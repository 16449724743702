import { gql } from '@apollo/client'
import { SUPPRESS_DEFAULT_ERROR } from '@firstbase/config/apollo'

import { PERSON_PROFILE_FULL, SELF_PROFILE_FULL } from './fragments'

export const UPDATE_SELF = gql`
  ${SELF_PROFILE_FULL}
  mutation updateSelf($updateOwnPerson: UpdateOwnPerson!) {
    updateSelf(updatePerson: $updateOwnPerson) {
      ...SelfProfileFull
    }
  }
`

export const UPDATE_PERSON_PROFILE = gql`
  ${PERSON_PROFILE_FULL}
  mutation updatePersonProfile($slug: String!, $updatePerson: UpdatePerson!) {
    updatePersonBySlugForOwnOrganization(
      slug: $slug
      updatePerson: $updatePerson
    ) {
      person {
        id
        slug
        ...PersonProfileFull
      }
      orderCreation {
        success
        errorMessage
      }
    }
  }
`

export const CREATE_PERSON = gql`
  mutation createPerson${SUPPRESS_DEFAULT_ERROR}($createPerson: CreatePerson!) {
    createPerson(createPerson: $createPerson) {
      person {
        id
        slug
        forename
        surname
        email
        packages {
          name
          slug
        }
        appAccess
        status
        newHire
        startDate
        secondaryEmail
        address {
          addressLine1
          addressLine2
          administrativeArea
          countryCode
          locality
          postalCode
          phoneNumber
        }
      }
      orderCreation {
        success
        errorMessage
      }
    }
  }
`
export const CREATE_PERSON_FOR_JIT_USER = gql`
  mutation CreatePersonForJITUser${SUPPRESS_DEFAULT_ERROR} {
    createPersonForJITUser {
      person {
        id
        slug
      }
    }
  }
`
export const RESEND_ARRANGE_RETURN_EMAIL = gql`
  mutation resendArrangeReturnEmail($personSlug: String!) {
    resendArrangeReturnEmail(personSlug: $personSlug)
  }
`

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation resendActivationEmail($personId: ID!) {
    resendActivationEmail(personId: $personId) {
      id
      slug
    }
  }
`

export const OFFBOARD_PERSON = gql`
  mutation offboardPerson($offboardPersonInput: OffboardPersonInput!) {
    offboardPerson(offboardPersonInput: $offboardPersonInput) {
      id
      status
    }
  }
`

export const REACTIVATE_ORG_PEOPLE = gql`
  mutation reactivateOrgPeople($personIds: [ID]!) {
    reactivateOrgPeople(personIds: $personIds) {
      id
    }
  }
`
