import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

/**
 * A custom hook that checks for a specific query parameter in the URL
 * This tells us whether or not a user was redirected from a successful
 * strip checkout
 */
const useCheckBuyOutSuccess = (): {
  isComingFromBuyout: boolean
  search: string
} => {
  const { search } = useLocation()

  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return {
      isComingFromBuyout: searchParams.has('pending_buy_out_successful'),
      search,
    }
  }, [search])
}

export default useCheckBuyOutSuccess
