import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './AlertWrapper.module.css'

const classNames = classNamesBind.bind(css)

type OwnProps = {
  children: React.ReactNode
}
const AlertWrapper = ({ children }: OwnProps) => (
  <div className={classNames('container')}>{children}</div>
)

export default AlertWrapper
