import React from 'react'
import classNamesBind from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import SVGIcons from '@firstbase/components/Atoms/SVGIcons'

import css from './closeicon.module.css'

const classNames = classNamesBind.bind(css)

interface CloseIconI {
  size?: string
  onClick: (() => void) | undefined
  attr?: {
    'data-testid'?: string
  }
}

const CloseIcon = ({ size = '32px', onClick, attr = {} }: CloseIconI) => {
  const { t } = useTranslation()
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.keyCode === 32) {
      if (onClick) {
        onClick()
      }
    }
  }

  return (
    <button
      type="button"
      className={classNames('close')}
      style={{ width: size, height: size }}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-label={t('Close')}
      {...attr}
    >
      <SVGIcons.Close className={classNames('close-icon')} />
    </button>
  )
}

export default CloseIcon
