import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '@firstbase/utils'
import { Icon, IconName, IconProps } from '../Icon'

const alertIconNameMap: Record<string, IconName> = {
  info: 'info',
  error: 'cross-circle',
  success: 'check-circle',
  warning: 'alert-triangle',
  neutral: 'info',
}

const alertIconVariants = cva('size-5 shrink-0', {
  variants: {
    variant: {
      info: 'text-blue-700',
      error: 'text-red-700',
      success: 'text-green-700',
      warning: 'text-orange-700',
      neutral: 'text-gray-700',
    },
    size: {
      md: 'size-5',
      sm: 'size-4',
    },
  },
  defaultVariants: {
    variant: 'neutral',
    size: 'md',
  },
})

interface AlertIconProps
  extends Omit<IconProps, 'name'>,
    VariantProps<typeof alertIconVariants> {}

const AlertIcon = React.forwardRef<SVGSVGElement, AlertIconProps>(
  ({ className, size, variant, ...props }, ref) => {
    if (!variant) {
      return null
    }

    return (
      <Icon
        ref={ref}
        name={alertIconNameMap[variant]}
        className={cn(alertIconVariants({ variant, size }), className)}
        {...props}
      />
    )
  }
)
AlertIcon.displayName = 'AlertIcon'

export { AlertIcon }
