import React from 'react'
import { withRouter } from 'react-router'
import { Translation } from 'react-i18next'
import classNamesBind from 'classnames/bind'

import { Typography } from '@firstbase/components'
import { PropsI, StateI } from './types'

import css from './errorboundary.module.css'

const classNames = classNamesBind.bind(css)

class ErrorBoundary extends React.Component<PropsI, StateI> {
  constructor(props: any) {
    super(props)
    this.state = { error: false, errorInfo: null }
  }

  static getDerivedStateFromError(errorInfo: any) {
    return { error: true, errorInfo }
  }

  componentDidCatch(error: any) {
    if (window.dtrum) {
      window.dtrum.reportError(error)
    }
  }

  render() {
    const { error, errorInfo } = this.state
    const { children, history } = this.props

    history.listen(() => {
      if (error) {
        this.setState({
          error: false,
        })
      }
    })

    if (error) {
      // You can render any custom fallback UI
      return (
        <Translation useSuspense={false}>
          {(t) => (
            <div className={classNames('container')}>
              <Typography variant="h1">
                {t('components.errorBoundary.Something went wrong.')}
              </Typography>
              <pre>
                <code>{errorInfo?.stack}</code>
              </pre>
            </div>
          )}
        </Translation>
      )
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
