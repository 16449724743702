import React from 'react'
import classNamesBind from 'classnames/bind'

import FLAGS from '@firstbase/constants/featureFlags'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Frame1 from './assets/Holiday/1.svg'
import Frame2 from './assets/Holiday/2.svg'
import Frame3 from './assets/Holiday/3.svg'
import Frame4 from './assets/Holiday/4.svg'
import Frame5 from './assets/Holiday/5.svg'
import Frame6 from './assets/Holiday/6.svg'
import Frame7 from './assets/Holiday/7.svg'
import Frame8 from './assets/Holiday/8.svg'
import Frame9 from './assets/Holiday/9.svg'
import css from './loading.module.css'

const classNames = classNamesBind.bind(css)

export interface OwnProps {
  size?: number
  color?: 'black' | 'white' | 'primary'
  theme?: 'holiday'
}

const Loading = ({ size = 5, color = 'primary', theme }: OwnProps) => {
  const flags = useFlags()

  const outerStyle = {
    height: `${size}rem`,
    width: `${size}rem`,
    borderWidth: size > 1 ? '2px' : '.3px',
  }

  const midStyle = {
    left: `${size * 0.3}rem`,
    right: `${size * 0.3}rem`,
    top: `${size * 0.3}rem`,
    bottom: `${size * 0.3}rem`,
    borderWidth: size > 1 ? '2px' : '.3px',
  }

  const innerStyle = {
    left: `${size * 0.14}rem`,
    right: `${size * 0.14}rem`,
    top: `${size * 0.14}rem`,
    bottom: `${size * 0.14}rem`,
    borderWidth: size > 1 ? '2px' : '.3px',
  }

  const themeWrapper = {
    maxWidth: `${size * 3}rem`,
    width: `${size * 3}rem`,
    maxHeight: `${size * 3}rem`,
    height: `${size * 3}rem`,
  }

  if (theme === 'holiday' && flags[FLAGS.SE_3019_HOLIDAY_LOADER]) {
    return (
      <div style={themeWrapper} className={classNames('theme')}>
        <Frame1 />
        <Frame2 />
        <Frame3 />
        <Frame4 />
        <Frame5 />
        <Frame6 />
        <Frame7 />
        <Frame8 />
        <Frame9 />
      </div>
    )
  }

  return (
    <div style={outerStyle} className={classNames('outer', color)}>
      <div style={innerStyle} className={classNames('inner', color)} />
      <div style={midStyle} className={classNames('mid', color)} />
    </div>
  )
}

export default Loading
