export const CUST_ADMIN = 'app:admin'
export const CUST_USER = 'app:user'
export const CUST_LEAVER = 'app:leaver'
export const CUST_ADMIN_READ_ONLY = 'app:admin:read-only'

type PermissionTypes =
  | typeof CUST_ADMIN
  | typeof CUST_USER
  | typeof CUST_LEAVER
  | typeof CUST_ADMIN_READ_ONLY

export type PermissionsT = Array<PermissionTypes>
