import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { SecureRoute, LoginCallback } from '@okta/okta-react'

import IDPAuth from '@firstbase/components/Molecules/IDPAuth'
import Activate from '@firstbase/components/Molecules/Activate'
import SignInForm from '@firstbase/components/Molecules/SignInForm'
import ErrorBoundary from '@firstbase/components/Atoms/ErrorBoundary'
import SignInPage from '@firstbase/components/Molecules/SignInForm/SignInPage'
import OktaProvider from '@firstbase/contexts/okta'
import { getOktaSignInConfig } from '@firstbase/config/okta'
import { LoadingOverlay } from '@firstbase/components/Atoms/Loader'
import environmentVariables, {
  getEnvironmentVariables,
} from '@firstbase/utils/environmentVariables'
import isEndToEndTest from '@firstbase/utils/isEndToEndTest/isEndToEndTest'

import initializeI18n from '@firstbase/config/i18n'

import '@firstbase/styles/mediaqueries.css'
import '@firstbase/styles/global.css'
import { ClickToComponent } from 'click-to-react-component'
import initializeDatadog from './config/datadog'

const App = lazy(() => import('./views/App'))
const ErrorLoadingAppliction = lazy(
  () => import('./views/ErrorLoadingApplication')
)
const DesignSystemPage = lazy(() =>
  import('@firstbase/views/DesignSystem').then((module) => ({
    default: module.DesignSystemPage,
  }))
)

getEnvironmentVariables()
  .then((environmentVariablesResult) => {
    environmentVariables.set(environmentVariablesResult)

    initializeI18n()
    if (!isEndToEndTest()) initializeDatadog()

    return (
      <React.StrictMode>
        <Suspense fallback={<LoadingOverlay color="black" />}>
          <BrowserRouter>
            <ErrorBoundary>
              <OktaProvider>
                <ClickToComponent />

                <Switch>
                  {environmentVariables.get().DEV && (
                    <Route path="/design-system" component={DesignSystemPage} />
                  )}

                  <Route
                    exact
                    path="/"
                    render={() => (
                      <SignInPage>
                        <SignInForm config={getOktaSignInConfig()} />
                      </SignInPage>
                    )}
                  />

                  <Route exact path="/auth" component={IDPAuth} />

                  <Route
                    exact
                    path="/auth/callback"
                    render={(props) => {
                      const onAuthResume = async () => {
                        props.history.replace('/')
                      }
                      return (
                        <LoginCallback
                          {...props}
                          onAuthResume={onAuthResume}
                          loadingElement={<LoadingOverlay />}
                        />
                      )
                    }}
                  />

                  <Route
                    exact
                    path="/auth/activate"
                    render={() => (
                      <SignInPage>
                        <Activate />
                      </SignInPage>
                    )}
                  />

                  <SecureRoute path="*">
                    <App />
                  </SecureRoute>
                </Switch>
              </OktaProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </Suspense>
      </React.StrictMode>
    )
  })
  .catch((error) => {
    // eslint-disable-next-line
    console.log(error)
    initializeI18n()
    return (
      <Suspense fallback={<LoadingOverlay color="black" />}>
        <ErrorLoadingAppliction />
      </Suspense>
    )
  })
  .then((reactElement: React.ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById('root'))
  })
