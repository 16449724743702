import { datadogRum } from '@datadog/browser-rum'

const initializeDatadogRum = (
  applicationId: string,
  clientToken: string,
  service: string,
  site: string,
  env: string
) =>
  datadogRum.init({
    applicationId,
    clientToken,
    service,
    site,
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

export default initializeDatadogRum
