import { useEffect, useRef } from 'react'

function useTimeouts() {
  const ref = useRef<NodeJS.Timeout[]>([])

  useEffect(() => () => ref.current.forEach(clearTimeout), [])
  useEffect(() => () => ref.current.forEach(clearInterval), [])

  return ref.current
}

export default useTimeouts
