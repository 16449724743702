import logo from '@firstbase/assets/firstbaseLogo.svg?url'

import environmentVariables from '@utils/environmentVariables'

const getOktaDomain = () => environmentVariables.get().VITE_OKTA_CUSTOMER_DOMAIN
const getOktaClientID = () =>
  environmentVariables.get().VITE_OKTA_CUSTOMER_CLIENT_ID

const getOktaAuthConfig = () => ({
  issuer: `https://${getOktaDomain()}/oauth2/default`,
  clientId: getOktaClientID(),
  redirectUri: `${window.location.origin}/auth/callback`,
})

const getOktaSignInConfig = () => ({
  logo,
  baseUrl: `https://${getOktaDomain()}`,
  clientId: getOktaClientID(),
  redirectUri: `${window.location.origin}/auth/callback`,
  features: {
    idpDiscovery: true,
    router: true,
  },
  idpDiscovery: {
    requestContext: environmentVariables.get().VITE_OKTA_IDP_REDIRECT,
  },
  i18n: {
    en: {
      // Labels
      'primaryauth.title': 'Sign in to your account',
      'primaryauth.username.placeholder': 'Email address',
      'password.forgot.email.or.username.placeholder': 'Email address',
      'password.forgot.email.or.username.tooltip': ' ',
      'password.reset': 'Set your password',
      'password.reset.title.generic': 'Set password',
      'password.newPassword.placeholder': 'Create password',
      'password.newPassword.tooltip': ' ',
      'password.confirmPassword.placeholder': 'Re-enter password',
      'password.confirmPassword.tooltip': ' ',
      'password.forgot.sendEmail': 'Set via Email',
    },
  },
})

export { getOktaAuthConfig, getOktaSignInConfig }
