import React, { ReactElement } from 'react'
import classNamesBind from 'classnames/bind'

import Icons from '@firstbase/components/Atoms/SVGIcons'

import css from './InlineAlert.module.css'

const classNames = classNamesBind.bind(css)

export enum Kinds {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  CAUTION = 'caution',
}

interface OwnProps {
  kind?: Kinds
  text: React.ReactNode
  noSmallMargin?: boolean
  noMargin?: boolean
  noMarginTop?: boolean
  needsExtraMargin?: boolean
  noMinHeight?: boolean // used to prevent vertical text overflow bug for when two lines of text
  needsExtraWidth?: boolean
}

const Alert = ({
  kind = Kinds.DEFAULT,
  text,
  noSmallMargin,
  noMargin,
  noMarginTop,
  needsExtraMargin = false,
  noMinHeight = false,
  needsExtraWidth = false,
}: OwnProps): ReactElement => {
  const renderIcon = () => (
    <div className={classNames('icon')}>
      {kind === Kinds.SUCCESS ? <Icons.AlertSuccess /> : <Icons.AlertWarning />}
    </div>
  )

  return (
    <div
      className={classNames('container', kind, {
        noSmallMargin,
        needsExtraMargin,
        noMargin,
        noMarginTop,
        minHeight: !noMinHeight,
        needsExtraWidth,
      })}
    >
      {renderIcon()}
      <div className={classNames('text')}>{text}</div>
    </div>
  )
}

export default Alert
