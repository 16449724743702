import { BadgeVariant } from '@firstbase/components'
import { DeployStatus } from '@globalTypes'

const DEPLOY_STATUS_BADGE_VARIANTS: Record<DeployStatus, BadgeVariant> = {
  [DeployStatus.AVAILABLE]: 'success',
  [DeployStatus.OUT_FOR_REPAIR]: 'informational',
  [DeployStatus.ON_HOLD]: 'informational',
  [DeployStatus.AWAITING_ARRIVAL]: 'informational',
  [DeployStatus.UNAVAILABLE]: 'informational',
  [DeployStatus.LOCKED]: 'caution',
  [DeployStatus.ARCHIVED]: 'destructive',
  [DeployStatus.GIFTED]: 'destructive',
  [DeployStatus.DEPLOYED]: 'default',
  [DeployStatus.VOIDED]: 'default',
}
export const getBadgeVariantForDeployStatus = (
  status: DeployStatus
): BadgeVariant => DEPLOY_STATUS_BADGE_VARIANTS[status] || 'default'
