import * as React from 'react'

import { cn } from '@firstbase/utils'

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => (
    <textarea
      className={cn(
'flex min-h-[80px] w-full rounded-lg border border-gray-300 pt-2 pr-2 pb-0 pl-4 text-gray-900 placeholder-gray-500 focus:border-gray-900 focus:text-gray-900 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-gray-300 disabled:placeholder-gray-400 hover:border-gray-500 hover:placeholder-gray-500',
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
Textarea.displayName = 'Textarea'

export { Textarea }
