import React from 'react'
import classNamesBind from 'classnames/bind'
import css from './ButtonRow.module.css'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  justifyEnd?: boolean
  spaceBetween?: boolean
  children: React.ReactNode
  noTopMargin?: boolean
  hideBorderSmall?: boolean
  noSticky?: boolean
  flexFill?: boolean
  baseline?: boolean
}

const ButtonRow = ({
  justifyEnd,
  spaceBetween,
  children,
  noTopMargin,
  hideBorderSmall,
  flexFill,
  noSticky,
  baseline,
}: OwnProps) => (
  <div
    className={classNames('buttons', 'buttonRow', {
      justifyEnd,
      spaceBetween,
      noTopMargin,
      hideBorderSmall,
      flexFill,
      noSticky,
      baseline,
    })}
  >
    {children}
  </div>
)

export default ButtonRow
