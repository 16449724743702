import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { getOktaAuthConfig } from '@firstbase/config/okta'

interface OwnProps {
  children: React.ReactNode
}

const Auth = ({ children }: OwnProps) => {
  const oktaAuth = useMemo(() => new OktaAuth(getOktaAuthConfig()), [])

  const history = useHistory()

  const customAuthHandler = () => {
    history.push('/')
  }

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string | null
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  useEffect(() => {
    oktaAuth.start()
    return () => {
      oktaAuth.stop()
    }
  }, [oktaAuth])

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  )
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Auth
