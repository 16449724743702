import { cn } from '@firstbase/utils'
import React from 'react'

interface CharacterCountProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: string
  maxLength: number
  isDisabled?: boolean
}

const CharacterCount = React.forwardRef<
  HTMLSpanElement,
  CharacterCountProps
>(({ value, maxLength, className, isDisabled, ...props }, ref) => (
    <span
      ref={ref}
      className={cn(
        'text-xs',
        isDisabled ? 'text-gray-400' : 'text-muted-foreground',
        className
      )}
      {...props}
    >
      {value.length} / {maxLength}
    </span>
  ))
CharacterCount.displayName = 'CharacterCount'

export { CharacterCount }
