import { Icon, IconName } from '@firstbase/components'
import React, { forwardRef } from 'react'

interface IconIllustrationProps {
  name: IconName
}

const IconIllustration = forwardRef<HTMLDivElement, IconIllustrationProps>(
  ({ name }, ref) => (
    <div
      ref={ref}
      className="flex size-12 items-center justify-center rounded-lg bg-pink-50"
    >
      <Icon name={name} className="h-7 max-w-7" />
    </div>
  )
)
IconIllustration.displayName = 'IconIllustration'

export { IconIllustration }
