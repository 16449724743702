import React from 'react'
import { cn } from '@firstbase/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const typographyVariants = cva('text-gray-900', {
  variants: {
    variant: {
      h1: 'text-2xl leading-[1.25] md:text-[2.5rem] font-bold',
      h2: 'text-2xl leading-8 font-bold',
      h3: 'text-lg leading-6',
      h4: 'text-base leading-6 font-bold',
      p: '',
      small: 'text-sm',
    },
    isBold: {
      true: 'font-bold',
    },
  },
  defaultVariants: {
    variant: 'p',
  },
})

interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof typographyVariants> {}

const Typography = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant, isBold, ...props }, ref) => {
    const Element = variant ?? 'p'
    return (
      <Element
        className={cn(typographyVariants({ variant, isBold, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Typography.displayName = 'Typography'

export { Typography }
