import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './loading-overlay.module.css'
import Loading, { OwnProps as LoadingProps } from './loading'

const classNames = classNamesBind.bind(css)

interface OwnProps extends LoadingProps {
  backgroundType?: 'transparent-dark' | 'light'
}

const LoadingOverlay = (OwnProps: OwnProps) => {
  const { backgroundType } = OwnProps

  return (
    <div className={classNames('container', backgroundType)}>
      <Loading {...OwnProps} theme="holiday" />
    </div>
  )
}

export default LoadingOverlay
