import React from 'react'
import classNamesBind from 'classnames/bind'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

import css from './error.module.css'

const classNames = classNamesBind.bind(css)

interface ErrorProps {
  label: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
}

const Error = ({ label }: ErrorProps) => (
  <div className={classNames('error')}>{label}</div>
)

export default Error
