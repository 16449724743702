import 'react-international-phone/style.css'

import {
  Input,
  InputProps,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '@firstbase/components'
import { cn } from '@firstbase/utils'
import React from 'react'
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
  UsePhoneInputConfig,
} from 'react-international-phone'

interface PhoneInputProps extends Omit<InputProps, 'value'> {
  config?: UsePhoneInputConfig
  value?: string
}

const PhoneInput = ({
  value,
  config,
  onChange,
  className,
  ...inputProps
}: PhoneInputProps) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      value,
      onChange: ({ phone }) => {
        const event = {
          target: { value: phone },
        } as React.ChangeEvent<HTMLInputElement>
        return onChange?.(event)
      },
      countries: defaultCountries,
      ...config,
    })

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Select
        value={country.iso2}
        onValueChange={(value) => setCountry(value as CountryIso2)}
      >
        <SelectTrigger className="flex w-20 items-center">
          <FlagImage iso2={country.iso2} />
        </SelectTrigger>

        <SelectContent>
          <SelectGroup>
            <SelectLabel>Countries</SelectLabel>
            {defaultCountries.map((c) => {
              const countryData = parseCountry(c)
              return (
                <SelectItem key={countryData.iso2} value={countryData.iso2}>
                  <div className="flex items-center">
                    <FlagImage
                      iso2={countryData.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <span className="mr-2">{countryData.name}</span>
                    <span className="text-gray-500">
                      +{countryData.dialCode}
                    </span>
                  </div>
                </SelectItem>
              )
            })}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Input
        type="tel"
        ref={inputRef}
        value={inputValue}
        onChange={handlePhoneValueChange}
        {...inputProps}
      />
    </div>
  )
}

PhoneInput.displayName = 'PhoneInput'

export { PhoneInput }
