import React, { ReactElement } from 'react'
import classNamesBind from 'classnames/bind'

import { NotificationParams } from '@firstbase/contexts/notifications/types'
import { useNotifications } from '@firstbase/contexts/notifications'
import CloseIcon from '@firstbase/components/Atoms/CloseIcon'
import Icons from '../SVGIcons'

import css from './Alert.module.css'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  notification: NotificationParams
}

const Alert = ({ notification }: OwnProps): ReactElement => {
  const { variant, id, message, submessage } = notification
  const { clearNotification } = useNotifications()

  const renderIcon = () => {
    if (variant === 'default') return null

    return (
      <div className={classNames('icon', `${variant}`)}>
        {variant === 'success' ? (
          <Icons.AlertSuccess />
        ) : (
          <Icons.AlertWarning />
        )}
      </div>
    )
  }

  const renderClose = () => (
    <div className={classNames('close')}>
      <CloseIcon onClick={() => clearNotification(id)} />
    </div>
  )

  return (
    <div className={classNames('container')}>
      {renderIcon()}
      <div className={classNames('content')}>
        <div className={classNames('message')}>{message}</div>
        {submessage ? (
          <div className={classNames('submessage')}>{submessage}</div>
        ) : null}
      </div>
      {renderClose()}
    </div>
  )
}

export default Alert
