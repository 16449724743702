import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import environmentVariables from '@firstbase/utils/environmentVariables'

import buildVersion from './buildVersion.json'

// Wrap w/ function in order to call once environment variables are loaded successfully
const initializeI18n = () =>
  i18n
    .use(Backend)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en-US',
      load: 'currentOnly',
      supportedLngs: ['en-US', 'en-GB'],
      // check if env vars defined, since this executes in the context that environment variables don't load
      debug:
        !environmentVariables.notDefinedYet &&
        environmentVariables.get().MODE !== 'production',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        queryStringParams: { v: buildVersion.version },
      },
    })

export default initializeI18n
