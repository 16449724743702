import React, { useRef } from 'react'
import { FieldError } from 'react-hook-form'
import classNamesBind from 'classnames/bind'

import Label from '@firstbase/components/Atoms/Label'
import SearchIcon from '@firstbase/assets/icons/search.svg'
import ClearIcon from './assets/clear.svg'

import css from './Input.module.css'

const classNames = classNamesBind.bind(css)

interface OwnProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string | number
  defaultValue?: string | number
  error?: string | FieldError
  label?: string
  htmlFor?: string
  disabled?: boolean
  onChange: (value: string) => void
}

const Search = ({
  property,
  value,
  defaultValue,
  error,
  label,
  htmlFor,
  disabled,
  onChange,
  ...rest
}: OwnProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const handleKeyDownClear = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      onChange('')
      inputRef?.current?.focus()
    }
  }

  const handleClear = () => {
    onChange('')
    inputRef?.current?.focus()
  }

  const renderLabel = () => {
    if (!label) return null
    return <Label label={label} htmlFor={htmlFor} />
  }

  const renderSearchIcon = () => (
    <div className={classNames('search-icon')}>
      <SearchIcon />
    </div>
  )

  const renderClear = () => {
    if (value) {
      return (
        <div
          className={classNames('clear-icon')}
          tabIndex={0}
          role="button"
          onKeyDown={handleKeyDownClear}
          onClick={handleClear}
        >
          <ClearIcon />
        </div>
      )
    }

    return null
  }

  return (
    // eslint-disable-next-line object-shorthand
    <div className={classNames('container', { disabled: disabled })}>
      {renderLabel()}
      <div className={classNames('input-wrapper')}>
        {renderSearchIcon()}
        <input
          ref={inputRef}
          defaultValue={defaultValue}
          className={classNames('input', 'search', {
            errorState: error,
            active: value,
          })}
          data-property={property}
          disabled={disabled}
          onChange={handleChange}
          value={value}
          {...rest}
        />
        {renderClear()}
      </div>
    </div>
  )
}

export default Search
