import CloseIcon from '@firstbase/assets/icons/close.svg'
import ReceiptIcon from '@firstbase/assets/icons/receipt.svg'
import DownIcon from '@firstbase/assets/icons/down.svg'
import ArrowIcon from '@firstbase/assets/icons/arrow.svg'
import WarningIcon from '@firstbase/assets/icons/warning.svg'
import SuccessIcon from '@firstbase/assets/icons/success.svg'
import EditIcon from '@firstbase/assets/icons/edit.svg'

const Close = CloseIcon
const AlertWarning = WarningIcon
const AlertSuccess = SuccessIcon
const Receipt = ReceiptIcon
const Down = DownIcon
const Arrow = ArrowIcon
const Edit = EditIcon

export default {
  CloseIcon,
  Close,
  AlertWarning,
  AlertSuccess,
  Receipt,
  Down,
  Arrow,
  Edit,
}
