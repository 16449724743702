import React from 'react'
import { useLocation } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

import { LoadingOverlay } from '@firstbase/components/Atoms/Loader'

const IDPAuth = () => {
  const { search } = useLocation()
  const { oktaAuth } = useOktaAuth()

  const params = new URLSearchParams(search)
  const idp = params.get('idp')
  if (idp) {
    oktaAuth.setOriginalUri('/')
    oktaAuth.signInWithRedirect({ idp })
  }

  return <LoadingOverlay color="black" />
}

export default IDPAuth
