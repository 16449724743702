import React from 'react'
import classNamesBind from 'classnames/bind'
import css from './SignInPage.module.css'

const classNames = classNamesBind.bind(css)

interface SignInPageProps {
  children?: React.ReactElement
}

const SignInPage = ({ children }: SignInPageProps) => (
  <div className={classNames('page')}>
    <main className={classNames('main')}>{children}</main>
  </div>
)

export default SignInPage
