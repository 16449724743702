import { gql } from '@apollo/client'

export const RETRIEVAL_BASICS = gql`
  fragment RetrievalBasics on RetrievalMethodResponse {
    id
    status
    code
    inboundDetails {
      trackingLink
      trackingCode
    }
    outboundDetails {
      trackingLink
      trackingCode
    }
  }
`
