import React from 'react'
import classNamesBind from 'classnames/bind'
import css from './loading-box.module.css'
import Loading from './loading'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  color?: 'black' | 'white' | 'primary'
  flexFill?: boolean
}

const LoadingOverlay = ({ color, flexFill }: OwnProps) => (
  <div className={classNames('container', { flexFill })}>
    <Loading color={color} />
  </div>
)

export default LoadingOverlay
