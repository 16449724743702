import { datadogLogs } from '@datadog/browser-logs'

const initializeDatadogLogs = (
  clientToken: string,
  service: string,
  site: string,
  env: string
) =>
  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })

export default initializeDatadogLogs
