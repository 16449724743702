import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'

import Alert, { AlertWrapper } from '@firstbase/components/Atoms/Alert'
import useTimeouts from '@firstbase/hooks/useTimeouts'

import {
  NotificationParams,
  AddNotification,
  DefaultApi,
  TriggerErrorNotificationFunction,
} from './types'

export const NotificationsContext = createContext<DefaultApi>({
  notifications: [],
  setNotification: () => ({} as NotificationParams),
  clearNotification: () => null,
})

export function NotificationsProvider({ children: childrenCallback }: any) {
  const [notifications, setNotifications] = useState<NotificationParams[]>([])
  const timeouts = useTimeouts()
  const { t } = useTranslation()

  const clearNotification = useCallback((id: number) => {
    setNotifications((values) => values.filter((n) => n.id !== id))
  }, [])

  const getMessages = useCallback(
    (
      notification: AddNotification
    ): {
      message?: string | React.ReactNode
      submessage?: string | React.ReactNode
    } => {
      const { variant } = notification
      let { message, submessage } = notification

      if (!message && !submessage && variant === 'error') {
        message = t('Alerts.An error occurred')
        submessage = t('Alerts.If this error persists, contact us for support')
      } else if (!message && !submessage && variant === 'success') {
        message = t('Alerts.Success!')
      }

      return { message, submessage }
    },
    [t]
  )

  const setNotification = useCallback(
    (notification: AddNotification) => {
      const { dontTimeout, timeout } = notification
      const newId = new Date().getTime()
      const { message, submessage } = getMessages(notification)
      const newNotification = {
        ...notification,
        id: newId,
        variant: notification.variant || 'default',
        message,
        submessage,
      } as NotificationParams

      setNotifications((prevState) => {
        if (!dontTimeout) {
          timeouts.push(
            setTimeout(() => {
              clearNotification(newId)
            }, timeout || 10000) // Default timeout is 10 seconds
          )
        }

        return [...prevState, newNotification]
      })

      return newNotification
    },
    [clearNotification, getMessages, timeouts]
  )

  const api = useMemo(
    () => ({
      notifications,
      setNotification,
      clearNotification,
    }),
    [notifications, setNotification, clearNotification]
  )

  const renderNotifications = () => (
    <AlertWrapper>
      {notifications.map((notification) => (
        <Alert key={notification.id} notification={notification} />
      ))}
    </AlertWrapper>
  )

  const triggerErrorNotification =
    useCallback<TriggerErrorNotificationFunction>(
      (message?: string, submessage?: string) =>
        setNotification({ message, submessage, variant: 'error' }),
      [setNotification]
    )

  const children = useMemo(
    () => childrenCallback(triggerErrorNotification),
    [childrenCallback, triggerErrorNotification]
  )
  return (
    <NotificationsContext.Provider value={api}>
      {children}
      {renderNotifications()}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationsContext)
}

export default NotificationsProvider
