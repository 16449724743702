import { cn } from '@firstbase/utils'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'
// TODO: when getting rid of the old checkbox, remove this import and start using the Icon component;
// You just need to use currentColor as the stroke color for the check icon in the check.svg file.
// alternatively, start using the new icons in the design system that Cassiano is working on.
import CheckIcon from './check.svg'

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer size-5 shrink-0 rounded-sm border-2 border-gray-500 bg-white ring-offset-background hover:border-pink-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-400 data-[state=checked]:border-0 data-[state=checked]:bg-pink-600 data-[state=checked]:text-white',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <CheckIcon className="size-3 stroke-current text-white" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
